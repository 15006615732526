<template>
    <header class="nav-bar">
        <div class="nav-bar__left">
            <NavLink
                v-if="!authStore.loggedIn"
                class="button dn-button button--secondary hidden-xxs hidden-xs hidden-sm hidden-md"
                :url="`/${SIGNUP_URL}`"
            >
                {{ becomeMemberText }}
            </NavLink>

            <BaseButton
                v-else-if="authStore.loggedIn"
                class="button--secondary hidden-xxs hidden-xs hidden-sm hidden-md"
                @click="modalStore.open({ name: MODAL_USER_CARD_SHEET })"
            >
                Mijn We Are Public-pas
            </BaseButton>

            <BaseButton
                v-if="!authStore.loggedIn"
                class="button--transparent hidden-xxs hidden-xs hidden-sm hidden-md"
                @click.prevent="modalStore.open({ name: MODAL_LOGIN })"
            >
                Inloggen
            </BaseButton>

            <NavLink
                v-else-if="authStore.loggedIn"
                class="button dn-button button--transparent hidden-xxs hidden-xs hidden-sm hidden-md"
                url="/profiel/overzicht"
            >
                Mijn profiel
            </NavLink>
        </div>

        <TheEscapeHatch class="nav-bar__home" />

        <div class="nav-bar__right">
            <TheSearchButton class="nav-bar__search" />

            <TheHamburgerMenu class="nav-bar__toggle" />
        </div>
    </header>
</template>

<script setup lang="ts">
import { applyOpacity, applyTransform } from '~/utils/transforms';
import { SIGNUP_URL } from '~/constants/membership';
import { MODAL_LOGIN, MODAL_USER_CARD_SHEET, useModalStore } from '~/store/modal';
import { useGlobalStore } from '~/store/global';
import { useAuthStore } from '~/store/auth';

const navBar = ref<HTMLElement|null>(null);
const modalStore = useModalStore();
const authStore = useAuthStore();
const globalStore = useGlobalStore();

const becomeMemberText = computed(() => globalStore.membershipTexts.becomeMemberCtaDefault);

const updateHeader = () => {
    const offsetTop = window.scrollY;

    applyOpacity(navBar.value, offsetTop);
    applyTransform(navBar.value, 0, offsetTop, 0.75);
};

onMounted(() => {
    navBar.value = document.querySelector('.nav-bar');

    window.addEventListener('scroll', updateHeader);

    updateHeader();
});

onUnmounted(() => {
    window.removeEventListener('scroll', updateHeader);
});
</script>

<style lang="less" src="./TheNavBar.less"></style>

<template>
    <BaseForm
        class="form-login"
        @submit="submitUserLogin"
    >
        <template #title>{{ title }}</template>

        <template #content>
            <FieldValidator
                v-slot="{ field, meta, errors, handleReset }"
                name="email"
            >
                <BaseFormField
                    :required="true"
                    :errors="errors"
                >
                    <BaseInput
                        v-bind="field"
                        type="email"
                        placeholder="E-mail"
                        :validator="meta"
                        @change="errorMessage = null"
                        @input="errorMessage = null"
                        @clear="handleReset"
                    />
                </BaseFormField>
            </FieldValidator>

            <FieldValidator
                v-slot="{ field, meta, errors, handleReset }"
                name="password"
            >
                <BaseFormField
                    :required="true"
                    :errors="errors"
                >
                    <BaseInput
                        v-bind="field"
                        type="password"
                        placeholder="Wachtwoord"
                        :validator="meta"
                        @change="errorMessage = null"
                        @input="errorMessage = null"
                        @clear="handleReset"
                    />
                </BaseFormField>
            </FieldValidator>

            <!-- eslint-disable -->
            <div
                v-if="!!errorMessage"
                class="form__error"
                v-html="errorMessage" 
            />
            <!-- eslint-enable -->
        </template>

        <template #actions>
            <BaseButton
                type="button"
                class="form-login__reset-password"
                @click.prevent="$emit('forgotPassword')"
            >
                Wachtwoord vergeten
            </BaseButton>

            <BaseButton
                :disabled="isSubmitting"
                type="submit"
                class="form__submit button--secondary"
            >
                Inloggen
                <template #after>
                    <BaseIcon icon="arrow-right" />
                </template>
            </BaseButton>
        </template>

        <template #after>
            <strong>
                {{ globalStore.membershipTexts.becomeMemberCtaLoginModal }}
            </strong>

            <p>
                {{ globalStore.membershipTexts.becomeMemberCtaLoginModalSmallText }}
            </p>

            <BaseButton
                :element="NuxtLink"
                :to="`/${SIGNUP_URL}`"
                type="button"
                class="button--secondary"
                @click="closeAllModals"
            >
                Lees meer
                <template #after>
                    <BaseIcon icon="arrow-right" />
                </template>
            </BaseButton>
        </template>
    </BaseForm>
</template>

<script setup lang="ts">
import { SIGNUP_URL } from '~/constants/membership';
import { useGlobalStore } from '~/store/global';
import { MODAL_LOGIN, MODAL_MAIN, useModalStore } from '~/store/modal';
import { LOGIN_INVALID_CREDENTIALS, LOGIN_INACTIVE_USER, STATUS } from '~/constants/errorMessages';
import { useForm, Field as FieldValidator } from 'vee-validate';
import { useAuthStore } from '~/store/auth';

defineEmits(['forgotPassword']);

defineProps({
    title: {
        type: String,
        default: null
    }
});

const NuxtLink = defineNuxtLink({});

const isSubmitting = ref(false);
const errorMessage = ref<string|null>(null);

const { handleSubmit } = useForm({
    validationSchema: {
        email: 'required|email',
        password: 'required'
    }
});

const globalStore = useGlobalStore();
const modalStore = useModalStore();
const authStore = useAuthStore();

const ERROR_INVALID_CREDENTIALS = 'invalid_credentials' as const;
const ERROR_MEMBERSHIP_EXPIRED = 'membership_expired' as const;

const submitUserLogin = handleSubmit(async(formData) => {
    isSubmitting.value = true;

    const { data, error } = await useFetchLaravel<{
        token: string,
        message: typeof ERROR_INVALID_CREDENTIALS | typeof ERROR_MEMBERSHIP_EXPIRED
    }>(
        '/v1.0/auth/jwt',
        {
            method: 'POST',
            body: formData
        }
    );

    if (error.value?.data?.message === ERROR_INVALID_CREDENTIALS) {
        errorMessage.value = LOGIN_INVALID_CREDENTIALS;
    } else if (error.value?.data?.message === ERROR_MEMBERSHIP_EXPIRED) {
        errorMessage.value = LOGIN_INACTIVE_USER;
    } else if (error.value?.statusCode && error.value.statusCode >= 400) {
        errorMessage.value = STATUS['500'];
    }

    if (data.value?.token) {
        const { setCookies } = useJwtCookies();

        setCookies(data.value.token);

        authStore.jwtToken = data.value.token;

        await useGetAndSetMember();

        closeLoginModal();

        refreshNuxtData();
    }

    isSubmitting.value = false;
});

const closeLoginModal = () => {
    modalStore.close({ name: MODAL_LOGIN });
};

const closeAllModals = () => {
    closeLoginModal();

    modalStore.close({ name: MODAL_MAIN });
};
</script>

<style lang="less" src="./FormLogin.less" />

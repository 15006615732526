<template>
    <BaseForm @submit="checkPassword">
        <template #title>
            Vul je nieuwe wachtwoord in
        </template>

        <template #content>
            <FieldValidator
                v-slot="{ field, meta, errors }"
                name="password"
            >
                <BaseFormField
                    :errors="errors"
                >
                    <template #label>Nieuw wachtwoord</template>
                    <BaseInput
                        v-bind="field"
                        type="password"
                        :validator="meta"
                        @change="errorMessage = null"
                        @input="errorMessage = null"
                    />
                </BaseFormField>
            </FieldValidator>

            <FieldValidator
                v-slot="{ field, meta, errors }"
                name="passwordConfirmation"
            >
                <BaseFormField
                    :errors="errors"
                >
                    <template #label>Herhaal nieuw wachtwoord</template>
                    <BaseInput
                        v-bind="field"
                        type="password"
                        :validator="meta"
                        @change="errorMessage = null"
                        @input="errorMessage = null"
                    />
                </BaseFormField>
            </FieldValidator>

            <div
                v-if="!!errorMessage"
                class="form__error"
            >
                {{ errorMessage }}
            </div>
        </template>

        <template #actions>
            <BaseButton
                type="submit"
                class="form__submit button--secondary form__button--max-width"
            >
                Opslaan
            </BaseButton>
        </template>

        <template #after>
            Je wachtwoord moet minimaal 8 tekens lang zijn.
        </template>
    </BaseForm>
</template>

<script setup lang="ts">
import { useForm, Field as FieldValidator } from 'vee-validate';
import {
    STATUS,
    PASSWORD_RESET_TOO_MANY_ATTEMPTS,
    PASSWORD_MISMATCH
} from '~/constants/errorMessages';
import { useEmailConfirmStore } from '~/store/emailConfirm';

const emit = defineEmits(['submitPassword']);

const emailConfirmationStore = useEmailConfirmStore();

const errorMessage = ref<string|null>(null);

const { handleSubmit, values } = useForm<{
    password: string;
    passwordConfirmation: string;
}>({
    validationSchema: {
        password: 'required|min:8',
        passwordConfirmation: 'required|min:8'
    }
});

const checkPassword = handleSubmit(async(formData) =>{
    if (formData.password !== formData.passwordConfirmation) {
        errorMessage.value = PASSWORD_MISMATCH;

        return;
    }

    const { error } = await useFetchLaravel('/v2.0/user/password-reset', {
        method: 'POST',
        body: {
            password: values.password,
            password_confirmation: values.passwordConfirmation,
            token: emailConfirmationStore.token,
            id: emailConfirmationStore.user?.id
        }
    });

    if (error.value?.statusCode === 429) {
        errorMessage.value = PASSWORD_RESET_TOO_MANY_ATTEMPTS;
    } else if (error.value) {
        errorMessage.value = STATUS['500'];
    } else {
        emit('submitPassword');
    }
});
</script>
